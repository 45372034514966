import { createSelector } from "@reduxjs/toolkit";

import { type CoachingVariation } from "features/coaching";
import { AdaApiSlice } from "slices";

export interface CoachingDTO {
  id: string;
  conversation_id: string;
  created: number;
  created_by: string;
  generative_actions_event_id: string;
  intent: string;
  intent_type: "free_text" | "generated";
  is_live: boolean;
  text: string;
  updated: number;
  updated_by: string;
  plan?:
    | "contextual_response"
    | "handoff"
    | "action_req_gathering"
    | "search_knowledge";
  module_id?:
    | "planner"
    | "contextual_response"
    | "handoff"
    | "action_req_gathering"
    | "search_knowledge";
  chosen_id?: string;
  rules?: string;
  article_chunk?: string;
  coaching_type:
    | "reply"
    | "action"
    | "process"
    | "search_knowledge"
    | "handoff";
  chosen_name: string;
  pending?: boolean;
}

interface CoachingReport {
  columnNames: string[];
  data: string[][];
}

export type Coaching = Pick<
  CoachingDTO,
  | "id"
  | "conversation_id"
  | "created"
  | "created_by"
  | "updated"
  | "updated_by"
  | "generative_actions_event_id"
  | "intent_type"
  | "is_live"
  | "coaching_type"
  | "chosen_name"
  | "pending"
> & {
  conversations: number;
  label: string;
} & CoachingVariation;

const transformResponse = (coachingDTO: CoachingDTO): Coaching => ({
  // Default values
  conversations: 0,
  ...coachingDTO,
  ...(() => {
    switch (coachingDTO.module_id) {
      case "contextual_response":
        return {
          original_module_id: "contextual_response",
          target_module_id: "contextual_response",
          text: coachingDTO.text,
          label: coachingDTO.text,
        };
      case "action_req_gathering":
        return {
          original_module_id: "action_req_gathering",
          target_module_id: "action_req_gathering",
          chosen_id: coachingDTO.chosen_id as string,
          label: "Run specific action",
        };

      case "search_knowledge":
        return {
          original_module_id: "search_knowledge",
          target_module_id: "search_knowledge",
          chosen_id: coachingDTO.chosen_id as string,
          article_chunk: coachingDTO.article_chunk as string,
          label: "Use specific knowledge",
        };

      case "planner":
        switch (coachingDTO.plan) {
          case "contextual_response":
            return {
              original_module_id: null,
              target_module_id: "contextual_response",
              text: coachingDTO.text,
              label: coachingDTO.text,
            };
          case "handoff":
            return {
              original_module_id: null,
              target_module_id: "handoff",
              chosen_id: coachingDTO.chosen_id as string,
              label: "Handoff",
            };
          case "action_req_gathering":
            return {
              original_module_id: null,
              target_module_id: "action_req_gathering",
              chosen_id: coachingDTO.chosen_id as string,
              label: "Run an action",
            };
          case "search_knowledge":
            return {
              original_module_id: null,
              target_module_id: "search_knowledge",
              chosen_id: coachingDTO.chosen_id as string,
              article_chunk: coachingDTO.article_chunk as string,
              label: "Use knowledge",
            };
          default:
            throw new Error("Invalid plan");
        }

      default:
        throw new Error("Invalid module_id");
    }
  })(),

  // Transformations
  updated: coachingDTO.updated * 1000,
  created: coachingDTO.created * 1000,
});

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["Coaching"],
});

const coachingApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCoachings: builder.query({
      query: () => ({
        url: "/coaching/",
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingDTO[] }) =>
        response.data.map(transformResponse),
      providesTags: ["Coaching"],
    }),
    getCoaching: builder.query<Coaching, string>({
      query: (id) => ({
        url: `/coaching/${id}/`,
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingDTO }) =>
        transformResponse(response.data),
      providesTags: ["Coaching"],
    }),
    getCoachingByConversationId: builder.query({
      query: (id: string) => ({
        url: `/coaching/conversation/${id}/`,
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingDTO[] }) =>
        response.data.map(transformResponse),
      providesTags: ["Coaching"],
    }),
    getCoachingReport: builder.query({
      query: ({ params }) => ({
        url: "ares/tabular/reports/coaching/",
        method: "GET",
        params,
      }),
      transformResponse: (response: CoachingReport) => response,
    }),
    testCoaching: builder.mutation<
      { message: string },
      Omit<
        CoachingDTO,
        | "id"
        | "created"
        | "created_by"
        | "updated"
        | "updated_by"
        | "coaching_type"
        | "chosen_name"
        | "pending"
      >
    >({
      query: (data) => ({
        url: "/coaching/evaluate",
        method: "POST",
        data: {
          ...data,
          // TODO: Fix BE to either accept null values or stop returning them
          plan:
            (data as { plan: string | null }).plan === null
              ? undefined
              : (data as { plan: string | null }).plan,
        },
      }),
      transformResponse: (response: { data: { message: string } }) =>
        response.data,
    }),
    createCoaching: builder.mutation<
      Coaching,
      Omit<
        CoachingDTO,
        | "id"
        | "created"
        | "created_by"
        | "updated"
        | "updated_by"
        | "coaching_type"
        | "chosen_name"
        | "pending"
      >
    >({
      query: (data) => ({
        url: "/coaching/",
        method: "POST",
        data,
      }),
      transformResponse: (response: { data: CoachingDTO }) =>
        transformResponse(response.data),
      invalidatesTags: ["Coaching"],
    }),
    updateCoaching: builder.mutation<Coaching, CoachingDTO>({
      query: (data) => ({
        url: `/coaching/${data.id}`,
        method: "PUT",
        data: {
          ...data,
          // TODO: Fix BE to either accept null values or stop returning them
          plan:
            (data as { plan: string | null }).plan === null
              ? undefined
              : (data as { plan: string | null }).plan,
        },
      }),
      transformResponse: (response: { data: CoachingDTO }) =>
        transformResponse(response.data),
      invalidatesTags: ["Coaching"],
    }),
    deleteCoaching: builder.mutation({
      query: (id: string) => ({
        url: `/coaching/${id}/`,
        method: "DELETE",
      }),
      transformResponse: (response: { data: CoachingDTO }) => response.data,
      invalidatesTags: ["Coaching"],
    }),
  }),
});

export const SelectAllCoachings = createSelector(
  coachingApi.endpoints.getCoachings.select({}),
  (res) => res.data ?? [],
);

export const {
  useGetCoachingsQuery,
  useGetCoachingQuery,
  useGetCoachingByConversationIdQuery,
  useGetCoachingReportQuery,
  useTestCoachingMutation,
  useCreateCoachingMutation,
  useUpdateCoachingMutation,
  useDeleteCoachingMutation,
} = coachingApi;
